import { useState, useMemo, useCallback } from 'react';
import Image from 'next/image';

import APP_IMAGES from '../../constants/appImages';
import { ASIDE_MENU } from '../../constants/menuList';
import { INDUSTRIES_DATA } from '../../constants/data';

const Industries = () => {
  const [isActiveTab, setIsActiveTab] = useState('Healthcare');
  const activeTabData = useMemo(() => INDUSTRIES_DATA?.find(data => data.name === isActiveTab), [isActiveTab]);

  const handleTabClick = useCallback((id) => {
    setIsActiveTab(id);
  }, [isActiveTab]);

  return (
    <section className="container mx-auto mt-12 sm:mt-14 md:mt-20 px-6 sm:px-10 lg:px-0">
      <h2 className="text-center font-bold text-2xl sm:text-4xl lg:text-5xl sm:leading-[72.72px] mb-6 sm:mb-16">
        Industries We Serve
      </h2>
      <h3 className="w-full sm:w-[90%] lg:w-[1020px] font-medium text-base sm:font-bold sm:text-2xl mx-auto text-gray-darker mb-8 sm:mb-16 text-center">
        Empowering Industries with Forward-Thinking and Innovative Software
        Solutions to Drive Evolution and Growth
      </h3>
      {/* For Desktop */}
      <div className="hidden lg:flex xl:max-w-5xl mx-auto 2xl:max-w-[1440px] px-10">
        <aside className="lg:w-[400px] xl:mr-8">
          <ul>
            {ASIDE_MENU.map(menu => (
              <li
                key={menu.text}
                onClick={() => handleTabClick(menu.id)}
                className={`w-full cursor-pointer flex items-center ${isActiveTab === menu.text ? 'ml-4' : 'lg:mb-12 xl:mb-9 '
                  }`}
              >
                <p
                  className={`w-fit flex items-center m-0 font-semibold md:text-lg xl:text-2xl xl:leading-7 ${isActiveTab === menu.text
                    ? 'text-white bg-primary py-4 pl-6 pr-6 lg:mb-12 xl:mb-9 rounded'
                    : 'text-gray-dark'
                    }`}
                >
                  {menu.text}
                  <span className="ml-6 inline-block">
                    {isActiveTab === menu.id && (
                      <Image
                        src={APP_IMAGES.arrowFarwardLongIcon}
                        alt="arrow"
                        width="auto"
                        height="auto"
                      />
                    )}
                  </span>
                </p>
              </li>
            ))}
          </ul>
        </aside>
        <div className="flex flex-col mt-10 2xl:max-w-7xl">
          <div className="mb-6">
            <h4 className="text-primary font-semibold text-[24px] leading-7 mb-4">
              {activeTabData.name}
            </h4>
            <p className="text-gray-dark font-normal text-xl leading-8 max-w-2xl">
              {activeTabData.description} <br /> {activeTabData.description1 && activeTabData.description1}
            </p>
          </div>
          <Image
            src={activeTabData.imgURL}
            alt={activeTabData.name}
            width={640}
            height={435}
            className="aspect-[3/2] object-contain"
          />
        </div>
      </div>

      {/* For Mobile */}
      <div className="lg:hidden">
        {INDUSTRIES_DATA.map(data => (
          <div key={data.name} className="mb-10">
            <h4 className="text-primary font-semibold text-lg sm:text-[24px] sm:leading-7 mb-2 sm:mb-4">
              {data.name}
            </h4>
            <p className="text-gray-dark font-normal text-sm leading-6 sm:text-xl mb-4">
              {data.description} <br /> {data.description1 && data.description1}
            </p>
            <div className='w-full relative'>
              <Image
                src={data.imgURL}
                alt={data.name}
                width={640}
                height={435}
                className="aspect-[3/2] object-contain"
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Industries;